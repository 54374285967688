import React, {Component} from "react";
import {
    Button,
    DataTable,
    DataTableCell,
    DataTableColumn,
    DataTableRowActions,
    Dropdown,
} from "@salesforce/design-system-react";
import Credentials from "../../../modals/Credentials";
import {fetchAccessRequests, revokeAccessRequests} from '../../../services/accessRequests';
import {CONSTANT_ACCESS_REQUEST} from "../../../constants/accessRequest";
import moment from 'moment';
import {fetchCredentials} from "../../../services/credentials";
import {ToastState} from "../../../utils/types/toast";
import {selectedAccountInfo} from "../../../utils/types/account";
import AccessRequestConfirmationModal from "../../../modals/AccessRequestConfirmationModal";
import {TOAST_MESSAGE} from "../../../constants/toastMessages";
import {AccessRequest} from "../../../utils/types/accessRequest";
import {fetchAccountDetails} from "../../../services/accounts";
import {HandleError} from "../../utils/common";
import AccessRequestDetails from "../../../modals/AccessRequestDetails";
import {getLoggedInUserEmail} from "../../../utils/lookup/session";
import {EmptyPageSpinner} from "../../../utils/components/spinner/page_spinner_overlay";

type CustomStatusCellProps = {
    children?: React.ReactNode;
    showCredentialModal: (item: any) => void;
    revokeAccess: (item: any) => void
    cancelAccess: (item: any) => void
    shareAccess: (item: any) => void
};

const CustomStatusCell: React.FC<CustomStatusCellProps> = ({ children, showCredentialModal,
                                                               revokeAccess, cancelAccess,
                                                               shareAccess, ...props }) => <td className='slds-text-align_right'>
    {children === CONSTANT_ACCESS_REQUEST.APPROVED_STATUS && <Button label={CONSTANT_ACCESS_REQUEST.GET_CREDENTIALS} onClick={() => showCredentialModal(props)} className='slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'/>}
    {children === CONSTANT_ACCESS_REQUEST.APPROVED_STATUS && <Button label={CONSTANT_ACCESS_REQUEST.REVOKE} onClick={() => {revokeAccess(props)}} variant="text-destructive" className='slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'/>}
    {children === CONSTANT_ACCESS_REQUEST.PENDING_STATUS && <Button label={CONSTANT_ACCESS_REQUEST.SHARE} onClick={() => {shareAccess(props)}} className='slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'/>}
    {children === CONSTANT_ACCESS_REQUEST.PENDING_STATUS && <Button label={CONSTANT_ACCESS_REQUEST.CANCEL} onClick={() => {cancelAccess(props)}} variant="text-destructive" className='slds-m-top--xx-small slds-m-bottom--xx-small slds-m-left--xxx-small'/>}
</td>;
CustomStatusCell.displayName = DataTableCell.displayName;

type CustomAccountIDCellProps = {
    children?: React.ReactNode;
    size: number
};
const CustomCell: React.FC<CustomAccountIDCellProps> = ({ children, size, ...props }) => {
    const breakTextIntoChunks = (text: string, chunkSize: number) => {
        const regex = new RegExp(`.{1,${chunkSize}}`, "g");
        return text.match(regex);
    };

    const textChunks = breakTextIntoChunks(children as string, size);

    return (
        <td className="slds-text-align_left">
            <p>
                {textChunks &&
                    textChunks.map((chunk, index) => (
                        <React.Fragment key={index}>
                            {chunk}
                            {index < textChunks.length - 1 && <br />}
                        </React.Fragment>
                    ))}
            </p>
        </td>
    );
};
CustomCell.displayName = DataTableCell.displayName;

type MyAccessRequestsProps = {
    isActive: boolean;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    requestType: string;
}

type MyAccessRequestsState = {
    items: any;
    selection: any[];
    hasMore: boolean;
    showCredentials: boolean;
    showAccountDetails: boolean;
    dataLoading: boolean;
    fetchedCredentials?: {
        AccessKeyID: string;
        SecretAccessKey: string;
        SessionToken: string;
        LoginURL: string;
    };
    showConfirmationModal: boolean,
    confirmationAction: string, // 'cancel' or 'revoke'
    selectedRequest: any,
    showRequestDetails: boolean,
};

class MyAccessRequests extends Component<MyAccessRequestsProps, MyAccessRequestsState> {
    static displayName = 'MyAccessRequests';
    state: MyAccessRequestsState = {
        items: [],
        selection: [],
        hasMore: true,
        showCredentials: false,
        showAccountDetails: false,
        dataLoading: true,
        showConfirmationModal: false,
        confirmationAction: '', // 'cancel' or 'revoke'
        selectedRequest: null,
        showRequestDetails: false,
    };

    handleRowAction = (item: AccessRequest, action: any): void => {
        if(action.id === "renew_request") {
            const reason_bucket = item.reason_bucket.split("-")
            const majorReason = reason_bucket[0].trim()
            const minorReason = reason_bucket.length === 1 ? "" : reason_bucket[1].trim()
            const detailedReason = item.detailed_reason.trim()

            this.props.setActiveSubTab(1)
            this.props.setSelectedAccount({
                account_id: item.aws_account,
                role: item.role_name,
                duration: item.duration,
                major_reason: majorReason,
                minor_reason: minorReason,
                detailed_reason: detailedReason,
                change_case: item.change_case,
                incident: item.incident,
                account_substrate: item.account_substrate
            });
        }
        if(action.id === "request_details") {
            this.handleRequestDetailsAction(item)
        }
    };

    handleRequestDetailsAction = (item: any) => {
        if (!this.state.showRequestDetails) {
            this.setState({
                selectedRequest: item
            })
        }
        this.setState({showRequestDetails: !this.state.showRequestDetails})
    }

    showCredentialModal = (item: any) => {
        if (!this.state.showCredentials) {
            this.setState({
                'dataLoading': true,
                selectedRequest: item
            })
            fetchCredentials(item.item.id).then((r) => {
                this.setState({
                    fetchedCredentials: {
                        AccessKeyID: r.AccessKeyID,
                        SecretAccessKey: r.SecretAccessKey,
                        SessionToken: r.SessionToken,
                        LoginURL: r.LoginURL
                    },
                    dataLoading: false
                });
            }).catch((error) => {
                HandleError(this.props, error, TOAST_MESSAGE.ERROR_FETCHING_CREDENTIALS);
                this.setState({'dataLoading': false})
            });
        }
        this.setState({showCredentials: !this.state.showCredentials,})
    }

    revokeAccessClickButton = (item: any) => {
        this.setState({
            confirmationAction: 'revoke',
            selectedRequest: item,
            showConfirmationModal: true,
        });
    }

    cancelAccessClickButton = (item: any) => {
        this.setState({
            confirmationAction: 'cancel',
            selectedRequest: item,
            showConfirmationModal: true,
        });
    }

    revokeAccess = (item: any) => {
        this.setState({'dataLoading': true})
        revokeAccessRequests(item.item.id).then((r) => {
            this.updateItems();
            this.props.setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ACCESS_REQUEST_REVOKE_DETAIL,
                    heading: TOAST_MESSAGE.ACCESS_REQUEST,
                },
                variant: "success"
            });
        }).catch((error) => {
            HandleError(this.props, error, TOAST_MESSAGE.ACCESS_REQUEST_REVOKE_ERROR);
            this.setState({'dataLoading': false})

        })
    }

    cancelAccess = (item: any) => {
        this.setState({'dataLoading': true})
        revokeAccessRequests(item.item.id).then((r) => {
            this.updateItems();
            this.props.setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ACCESS_REQUEST_CANCEL_DETAIL,
                    heading: TOAST_MESSAGE.ACCESS_REQUEST,
                },
                variant: "success"
            });
        }).catch((error) => {
            HandleError(this.props, error, TOAST_MESSAGE.ACCESS_REQUEST_CANCEL_ERROR);
            this.setState({'dataLoading': false})

        })
    }

    confirmAction = () => {
        const { confirmationAction, selectedRequest } = this.state;

        if (confirmationAction === 'revoke') {
            this.revokeAccess(selectedRequest);
        } else if (confirmationAction === 'cancel') {
            this.cancelAccess(selectedRequest);
        }

        // Close the modal and reset state
        this.setState({
            showConfirmationModal: false,
            confirmationAction: '',
            selectedRequest: null,
        });
    };

    shareAccess = async (item: any) => {
        let url = new URL(window.location.origin);
        url.pathname = "/access-requests/" + item.item.id
        try {
            await navigator.clipboard.writeText(url.toString());  // Replace 'Your text to copy' with the value you want to copy.
            this.props.setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.SHARE_ACCESS_DETAILS,
                    heading: TOAST_MESSAGE.SHARE_ACCESS,
                },
                variant: "success"
            });
            console.log('Text copied to clipboard');
        } catch (err: any) {
            HandleError(this.props, err, TOAST_MESSAGE.ERROR_SHARING_ACCESS_DETAILS);
            console.error('Failed to copy text: ', err);
        }
    }

    calculateRequestStatus = (accessRequest: any): string => {
        if (accessRequest.approved_at !== 0) return "approved";
        if (accessRequest.rejected_at !== 0) return "rejected";
        return "pending";
    };

    sortByStatus = (a: AccessRequest, b: AccessRequest): number => {
        const order = ["approved", "pending", "rejected"];
        return order.indexOf(a.status) - order.indexOf(b.status);
    };

    componentDidMount() {
        this.updateItems();
    }

    componentDidUpdate(prevProps: MyAccessRequestsProps) {
        // Check if the component just became active
        if (!prevProps.isActive && this.props.isActive) {
            this.updateItems();
        }
    }

    updateItems() {
        this.setState({'dataLoading': true})
        const user: string = getLoggedInUserEmail()

        fetchAccessRequests(user)
            .then(data => {
                const fetchDetailsPromises = data.map((accessRequest: any) => {
                    // Fetch account details asynchronously
                    return fetchAccountDetails(accessRequest.aws_account)
                        .then(accountData => ({
                            ...accessRequest,
                            status: this.calculateRequestStatus(accessRequest),
                            account_substrate: accountData?.account_substrate || "aws", // Currently, only for GCP accounts, we get the account_substrate field value from backend.
                            name: accountData.name
                        }))
                        .catch(error => {
                            console.error("Error fetching account details:", error);
                            return {
                                ...accessRequest,
                                status: this.calculateRequestStatus(accessRequest),
                                name: "Unknown"
                            };
                        });
                });

                // Wait for all fetches to complete
                return Promise.all(fetchDetailsPromises)
                    .then(transformedData => {
                        // Sort the transformed data
                        transformedData.sort(this.sortByStatus);

                        // Map and transform the data
                        return transformedData.map(accessRequest => {
                            let role_label = accessRequest.role_name;
                            if (accessRequest.role_name.startsWith("TEAM_DEFINED_PCSKROLE")) {
                                role_label = role_label.replace("TEAM_DEFINED_PCSKROLE", "")
                                role_label = role_label.substring(1);
                            }
                            let expiresAt = moment(moment.unix(accessRequest.expires_at)).fromNow(true) + " remaining (" +
                                moment(moment.unix(accessRequest.expires_at)).format('M/D HH:mm') + ")";
                            return {
                                id: accessRequest.id,
                                approved_by: accessRequest.approved_by,
                                approved_at: accessRequest.approved_at,
                                aws_account: accessRequest.aws_account,
                                aws_account_display: accessRequest.name + " (" + accessRequest.aws_account + ")",
                                duration: accessRequest.duration,
                                expired: accessRequest.expired,
                                expires_at: expiresAt,
                                reason: accessRequest.reason_bucket + " - " + accessRequest.reason,
                                reason_bucket: accessRequest.reason_bucket,
                                detailed_reason: accessRequest.reason,
                                rejected_at: accessRequest.rejected_at,
                                rejected_by: accessRequest.rejected_by,
                                revoked_at: accessRequest.revoked_at,
                                revoked_by: accessRequest.revoked_by,
                                role_name: role_label,
                                sfdc_email: accessRequest.sfdc_email,
                                sfdc_user: accessRequest.sfdc_user,
                                timestamp: accessRequest.timestamp,
                                status: this.calculateRequestStatus(accessRequest),
                                name: accessRequest.name,
                                account_substrate: accessRequest.account_substrate,
                                change_case: accessRequest.change_case,
                                incident: accessRequest.incident
                            };
                        });
                    });
            })
            .then(finalTransformedData => {
                this.setState({ items: finalTransformedData, dataLoading: false });
            })
            .catch(error => {
                HandleError(this.props, error, TOAST_MESSAGE.ERROR_FETCHING_ACCESS_REQUESTS);
                console.error("Error fetching access requests:", error);
                this.setState({ 'dataLoading': false });
            });

    }

    render() {
        if (this.state.dataLoading) {
            if (this.props.requestType === "view" || this.props.requestType === "reject" || this.props.requestType === "approve") {
                // spinner is taken over by the components for such request types
                return  (<></>)
            }
            return (<EmptyPageSpinner/>);
        }
        return (
            <div className='slds-m-bottom--xx-large' style={{minHeight: "80vh"}}>
                {this.state.items.length > 0 ? (
                    <DataTable
                    striped={true}
                    items={this.state.items}
                    id="MyAccessRequestsTable"
                >
                    <DataTableColumn label={CONSTANT_ACCESS_REQUEST.ACCOUNT} primaryColumn property="aws_account_display">
                        <CustomCell size={60}/>
                    </DataTableColumn>
                    <DataTableColumn label={CONSTANT_ACCESS_REQUEST.ROLE} property="role_name">
                        <CustomCell size={50}/>
                    </DataTableColumn>
                    <DataTableColumn label={CONSTANT_ACCESS_REQUEST.TIME_REMAINING} property="expires_at" />
                    <DataTableColumn width="23rem" property="status">
                        <CustomStatusCell showCredentialModal={this.showCredentialModal} revokeAccess={this.revokeAccessClickButton}
                                          cancelAccess={this.cancelAccessClickButton} shareAccess={this.shareAccess}/>
                    </DataTableColumn>
                    <DataTableRowActions options={[
                        {
                            id: 'request_details',
                            label: CONSTANT_ACCESS_REQUEST.REQUESTS_DETAILS,
                            value: 'request_details',
                        },
                        {
                            id: 'renew_request',
                            label: CONSTANT_ACCESS_REQUEST.CLONE_REQUESTS,
                            value: 'renew_request',
                        }
                    ]}
                     onAction={this.handleRowAction}
                     dropdown={<Dropdown length="7" />}/>
                </DataTable>
                ) : (
                    <div className='slds-page-header'>No Access Requests</div>
                )}
                <Credentials isOpen={this.state.showCredentials} toggleModal={this.showCredentialModal} fetchedCredentials={this.state.fetchedCredentials}
                             requestDetails={this.state.selectedRequest} toast={this.props.toast} setToast={this.props.setToast}/>
                <AccessRequestConfirmationModal
                    isOpen={this.state.showConfirmationModal}
                    toggleModal={(isOpen) => this.setState({ showConfirmationModal: isOpen })}
                    onConfirm={this.confirmAction}
                    actionType={this.state.confirmationAction === 'revoke' ? CONSTANT_ACCESS_REQUEST.REVOKE : CONSTANT_ACCESS_REQUEST.CANCEL}
                />
                <AccessRequestDetails isOpen={this.state.showRequestDetails} toggleModal={this.handleRequestDetailsAction}
                                      setToast={this.props.setToast} requestDetails={this.state.selectedRequest} setActiveTab={this.props.setActiveTab}
                                      setActiveSubTab={this.props.setActiveSubTab} setSelectedAccount={this.props.setSelectedAccount}
                />
            </div>
        )
    }

}

export default MyAccessRequests