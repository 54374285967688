import React, { Component} from "react";
import {
    Button,
    Card,
    Combobox, comboboxAddSubheadings, comboboxFilterAndLimit, Input,
    Lookup, Radio,
    RadioGroup,
    Textarea
} from "@salesforce/design-system-react";
import Approvers from "./Approvers";
import { fetchReasons } from '../../../services/reasons';
import {fetchAccounts, fetchSlackChannel} from "../../../services/accounts";
import {PCSK_ROLES, ROLES} from '../../../constants/role';
import {CONSTANT_ACCESS_REQUEST, RECORD_TYPE} from "../../../constants/accessRequest";
import {fetchRoles} from "../../../services/roles";
import {ToastState} from "../../../utils/types/toast";
import {selectedAccountInfo} from "../../../utils/types/account";
import {fetchApprovers} from "../../../services/approvers";
import {createAccessRequest} from "../../../services/accessRequests";
import {TOAST_MESSAGE} from "../../../constants/toastMessages";
import {HandleError} from "../../utils/common";
import EmptyPageSpinner from "../../../utils/components/spinner/page_spinner_overlay";
import {fetchChangeCaseStatus} from "../../../services/validations";

type CreateAccessRequestsProps = {
    setActiveSubTab: (tabIndex: number) => void;
    isActive: boolean;
    selectedAccount: selectedAccountInfo;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
};

type Approver = {
    email: string;
    tags: string[];
}

type Account = {
    id: string
    name: string;
    email: string;
    account_substrate: string;
    account_status: string;
    team_name: string;
}

type Role = {
    id: string;
    label: string;
    description: string;
    value: string;
    hours: number[];
    account_id: string;
    path: string;
    role_substrate: string;
    type: string;
}

type Duration = {
    id: string
    label: string;
    value: number;
}

type CreateAccessRequestsState = {
    approvers: Approver[];
    slack_channels: any;
    accounts: Account[];
    roles: Role[];
    durations: Duration[];
    major_reasons: any[];
    minor_reasons: any[];
    selectedAccount: string;
    selectedTeamName: string;
    selectedAccountIndex: number;
    accountStatus: string;
    selectedRole: Role[];
    selectedRoleDescription: string;
    selectedDuration: any;
    selectedMajorReason: any;
    selectedMinorReason: any;
    selectedMinorReasonInfo: string;
    loadingApprovers: boolean;
    roleSubheadings: any[];
    detailedReason: string,
    isRoleDisabled: boolean;
    isDurationDisabled: boolean;
    isMajorReasonDisabled: boolean;
    isMinorReasonDisabled: boolean;
    isDetailedReasonDisabled: boolean;
    dataLoading: boolean;
    isCreateButtonDisabled: boolean;

    isRecordDetailsVisible: boolean;
    recordDetailsTypeSelected: string;
    recordDetailsTypeSelectedInputPlaceholder: string;
    recordDetailsSelectedValue: string;
    recordDetailsSelectedInputError: string;
    recordDetailsVerificationLoading: boolean;
    isRecordDetailsValid: boolean;
    recordSubject: string;
};

class CreateAccessRequests extends Component<CreateAccessRequestsProps, CreateAccessRequestsState> {
    constructor(props: CreateAccessRequestsProps) {
        super(props);
        this.state = {
            approvers : [],
            accounts: [],
            slack_channels: {},
            roles: [],
            durations: [],
            major_reasons: [],
            minor_reasons: [],
            selectedAccount: "",
            selectedTeamName: "",
            selectedAccountIndex: -1,
            accountStatus: "",
            selectedRole: [],
            selectedRoleDescription: "",
            selectedDuration: [],
            selectedMajorReason: [],
            selectedMinorReason: [],
            selectedMinorReasonInfo: "",
            loadingApprovers: false,
            roleSubheadings : [],
            detailedReason: "",
            isRoleDisabled: true,
            isDurationDisabled: true,
            isMajorReasonDisabled: true,
            isMinorReasonDisabled: true,
            isDetailedReasonDisabled: true,
            dataLoading: true,
            isCreateButtonDisabled: true,
            isRecordDetailsVisible: false,
            recordDetailsTypeSelected: "change_case",
            recordDetailsTypeSelectedInputPlaceholder: CONSTANT_ACCESS_REQUEST.VALID_CHANGE_CASE,
            recordDetailsSelectedValue: "",
            recordDetailsSelectedInputError: "",
            recordDetailsVerificationLoading: false,
            isRecordDetailsValid: false,
            recordSubject: ""
        }
    }

    componentDidMount() {
        this.updateAccounts(this.props.selectedAccount?.account_id || '');
        this.updateReasons();
        this.updateSlackChannels();
        if (this.props.selectedAccount?.account_id !== '' && this.props.selectedAccount?.account_id !== undefined) {
            this.setState({'dataLoading': true});
            this.setSelectedAccount(this.props.selectedAccount?.account_id);
            this.handleAccountSelect({id: this.props.selectedAccount?.account_id || '',
                account_substrate: this.props.selectedAccount.account_substrate || ""}).then( r => {
                this.setState({'dataLoading': false});
            }).catch(r => {
                this.setState({'dataLoading': false});
            });
        }
    }

    componentDidUpdate(prevProps: CreateAccessRequestsProps, prevState: CreateAccessRequestsState) {
        // Check if the component just became active
        if (!prevProps.isActive && this.props.isActive) {
            this.updateAccounts(this.props.selectedAccount?.account_id || '');
            this.updateReasons();
            this.clearSelections();
            if (this.props.selectedAccount?.account_id !== '' && this.props.selectedAccount?.account_id !== undefined) {
                this.setState({'dataLoading': true})
                this.setSelectedAccount(this.props.selectedAccount?.account_id);
                const selectedAccountInfo = this.state.accounts.find(account => account.id === this.props.selectedAccount?.account_id)
                this.handleAccountSelect({id: this.props.selectedAccount?.account_id || '',
                    account_status: selectedAccountInfo?.account_status, team_name: selectedAccountInfo?.team_name,
                    account_substrate: selectedAccountInfo?.account_substrate}).then(r => {
                    if (this.props.selectedAccount?.role !== '' && this.props.selectedAccount?.role !== undefined){
                        const roleDetails = this.state.roles.find(role => role.value === this.props.selectedAccount?.role
                            || role.label === this.props.selectedAccount?.role)
                        const roleName = roleDetails?.value || ""
                        this.handleRoleSelect({}, {
                            selection: [
                                {
                                    account_id: this.props.selectedAccount?.account_id,
                                    description: PCSK_ROLES[roleName] ? PCSK_ROLES[roleName].description: roleDetails?.description,
                                    hours: roleDetails?.hours,
                                    label: PCSK_ROLES[roleName] ? PCSK_ROLES[roleName].displayName: roleDetails?.label,
                                    path: roleDetails?.path,
                                    role_substrate: roleDetails?.role_substrate,
                                    value: roleDetails?.value,
                                }
                            ]
                        }).then(() => {
                            const major_reason = this.state.major_reasons.find(reason => reason.value === this.props.selectedAccount?.major_reason);
                            const roleHours = roleDetails?.hours || []
                            const convertHours = this.convertToHourList(roleHours)
                            const selectedDuration = convertHours.find(hour => hour.value === this.props.selectedAccount?.duration);
                            const detailed_reason = this.props.selectedAccount?.detailed_reason || "";
                            this.setState({
                                selectedDuration: [selectedDuration],
                                minor_reasons: major_reason.minor_reasons,
                                selectedMajorReason: [major_reason],
                                detailedReason: detailed_reason,
                                isRoleDisabled: false,
                                isMajorReasonDisabled: false,
                            })
                            const minor_reason = this.state.minor_reasons.find(reason => reason.id === this.props.selectedAccount?.minor_reason);
                            this.setState({
                                selectedMinorReason: [minor_reason],
                                isMinorReasonDisabled: !minor_reason,
                                isDetailedReasonDisabled: false,
                                dataLoading: false,
                            });
                            if(minor_reason) {
                                this.setState({
                                    selectedMinorReasonInfo: minor_reason.info_prompted
                                });
                            }
                            if(this.state.isRecordDetailsVisible) {
                                if(this.props.selectedAccount.change_case === CONSTANT_ACCESS_REQUEST.EMERGENCY
                                    && this.props.selectedAccount.incident === CONSTANT_ACCESS_REQUEST.EMERGENCY) {
                                    this.handleRecordDetailsInput({target: {value: ""}})
                                    this.setState({
                                        recordDetailsTypeSelected: RECORD_TYPE.EMERGENCY
                                    })
                                }
                                else {
                                    if(this.props.selectedAccount.change_case?.length !== 0) {
                                        this.handleRecordDetailsInput({target: {value: this.props.selectedAccount.change_case || ""}})
                                        this.setState({
                                            recordDetailsTypeSelected: RECORD_TYPE.CHANGE_CASE
                                        })
                                    }
                                    if(this.props.selectedAccount.incident?.length !== 0) {
                                        this.handleRecordDetailsInput({target: {value: this.props.selectedAccount.incident || ""}})
                                        this.setState({
                                            recordDetailsTypeSelected: RECORD_TYPE.INCIDENT
                                        })
                                    }
                                }
                            }
                        })
                    }
                    else {
                        this.setState({'dataLoading': false})
                    }
                });
            }
        }

        if (
            prevState.selectedAccountIndex !== this.state.selectedAccountIndex ||
            prevState.selectedRole !== this.state.selectedRole ||
            prevState.selectedDuration !== this.state.selectedDuration ||
            prevState.detailedReason !== this.state.detailedReason ||
            prevState.selectedMajorReason !== this.state.selectedMajorReason ||
            prevState.minor_reasons !== this.state.minor_reasons ||
            prevState.selectedMinorReason !== this.state.selectedMinorReason ||
            prevState.recordDetailsSelectedValue !== this.state.recordDetailsSelectedValue ||
            prevState.isRecordDetailsValid !== this.state.isRecordDetailsValid ||
            prevState.recordDetailsTypeSelected !== this.state.recordDetailsTypeSelected
        ) {
            this.setState({
                isCreateButtonDisabled: !this.validated()
            });
        }
    }

    updateAccounts(selectedAccountID: string) {
        fetchAccounts()
            .then(data => {
                const transformedData = data.map((account: Account) => ({
                    id: account.id,
                    name: account.name,
                    label: account.name + " (" + account.id + ") " + account.email + " (" + account.account_substrate.toUpperCase() + ")",
                    value: account.id,
                    account_substrate: account.account_substrate,
                    account_status: account.account_status,
                    team_name: account.team_name
                })).sort((a: any, b: any) => {
                    return a.name.localeCompare(b.name);
                });
                this.setState({'accounts': transformedData});
                if (selectedAccountID !== "") {
                    this.setSelectedAccount(selectedAccountID);
                }
                this.setState({'dataLoading': false})
            })
            .catch(error => {
                HandleError(this.props, error, TOAST_MESSAGE.ERROR_FETCHING_ACCOUNTS);
                this.setState({
                    dataLoading: false
                });
            });
    }
    updateReasons() {
        fetchReasons()
            .then(data => {
                const transformedData = data.map((reason: { label: string; description: string,
                    minor_reasons:{ label: string; value: string; description: string, info_prompted: string}[] }) => ({
                    id: reason.label,
                    label: reason.label + " ( " + reason.description + " )",
                    value: reason.label,
                    description: reason.description,
                    minor_reasons: reason.minor_reasons.map((minorReason: { label: string; value: string; description: string, info_prompted: string}) => ({
                        id: minorReason.label,
                        displayLabel: minorReason.label,
                        label: minorReason.label + " - " + minorReason.description,
                        value: minorReason.value,
                        description: minorReason.description,
                        info_prompted: minorReason.info_prompted
                    }))
                }));
                this.setState({'major_reasons': transformedData});
            })
            .catch(error => {
                HandleError(this.props, error, TOAST_MESSAGE.ERROR_FETCHING_REASONS);
                this.setState({'dataLoading': false})
                console.error("Error fetching reasons data:", error)
            })
    }

    updateSlackChannels() {
        fetchSlackChannel().then((data) => {
            const slackChannelMap = data.reduce((acc: { [key: string]: string }, account: any) => {
                acc[account.name] = account.slack_channel;
                return acc;
            }, {});
            this.setState({slack_channels: slackChannelMap})
        }).catch(error => {
            HandleError(this.props, error, TOAST_MESSAGE.ERROR_FETCHING_SLACK_CHANNEL);
            this.setState({'dataLoading': false})
            console.error("Error fetching slack channel data:", error)
        })
    }

    async handleRoleSelect(event: any, data: any) {
        const selection =
            data.selection.length === 0
                ? this.state.selectedRole
                : data.selection;
        this.setState({
            'selectedRole': data.selection,
            'durations': this.convertToHourList(selection[0].hours),
            'selectedDuration': [],
            'approvers': [],
            'loadingApprovers': true,
            'selectedRoleDescription': selection[0].description,
            'isDurationDisabled': false
        });
        if (selection.length !== 0 && selection[0].hours.length !== 0) {
            this.setState({
                'selectedDuration': [
                    {
                        id: selection[0].hours[0].toString(),
                        label: this.displayHour(selection[0].hours[0]),
                        value: selection[0].hours[0]
                    }
                ],
                isMajorReasonDisabled: false
            })
        }

        let roleConfigAdditionalInfo = this.isRoleChangeCaseValidationReqd(data.selection[0])
        if (roleConfigAdditionalInfo) {
            this.setState({
                'isRecordDetailsVisible': true,
                'recordDetailsTypeSelected': RECORD_TYPE.CHANGE_CASE,
            })
        } else {
            this.setState({
                'isRecordDetailsVisible': false,
                'recordDetailsSelectedValue': ""
            })
        }

        // Set Approvers data
        fetchApprovers(data.selection[0].account_id, data.selection[0].value).then((response) => {
            this.setState({'approvers': response, 'loadingApprovers': false});
        }).catch((err: any) => {
            HandleError(this.props, err, TOAST_MESSAGE.ERROR_FETCHING_APPROVERS);
            console.error("Error fetching approvers:", err.message);
            this.setState({
                'loadingApprovers': false,
                'dataLoading': false
            });
        });
    }

    isRoleChangeCaseValidationReqd(roleDetails: any) {
        let roleChangeCaseValidationReqd = `${process.env.REACT_APP_PCSK_ROLE_CHANGE_CASE_VALIDATION_REQD}`;

        for (let roleConfig of roleChangeCaseValidationReqd.split(",")) {
            if (roleConfig.startsWith("TEAM_DEFINED_PCSKROLE") && roleDetails.value.startsWith("TEAM_DEFINED_PCSKROLE")) {
                return true
            }
            if (roleConfig.startsWith("/pcsk/custom/admin") && roleDetails.path.startsWith("/pcsk/custom/admin")) {
                return true
            }
            if (roleConfig.startsWith("/pcsk/custom") && roleDetails.path.startsWith("/pcsk/custom")) {
                return true
            }
            if (roleConfig === roleDetails.value) {
                return true
            }
        }
        return false
    }

    async handleAccountSelect(item: any) {
        this.clearSelections();
        const selectedIndex = this.state.accounts.findIndex(opt => opt.id === item.id);
        this.setState({
            selectedAccountIndex: selectedIndex,
            selectedAccount: item.id,
            selectedTeamName: item.team_name,
            isRoleDisabled: false,
            accountStatus: item.account_status
        });
        try {
            const apiData: any = await fetchRoles(item.id);

            // Transforming the API data to the desired format
            const transformedData = apiData.map((role: { id: any; name: string; hours: number[], account_id: string, role_substrate: string, path: string }) => {
                let label = PCSK_ROLES[role.name] ? PCSK_ROLES[role.name].displayName : role.name;

                // Check if role.name starts with "TEAM_DEFINED_PCSKROLE" and remove it from the label
                if (role.name.startsWith("TEAM_DEFINED_PCSKROLE")) {
                    label = label.replace("TEAM_DEFINED_PCSKROLE", "")
                    label = label.substring(1);
                }

                return {
                    id: role.role_substrate + role.account_id + role.name,
                    label: label,
                    description: PCSK_ROLES[role.name] ? PCSK_ROLES[role.name].description : "",
                    value: role.name,
                    hours: role.hours,
                    account_id: role.account_id,
                    path: role.path,
                    role_substrate: role.role_substrate,
                    type: this.calculateRoleType(role)
                };
            });

            if (item.account_substrate === "aws") {
                this.setState({
                    roleSubheadings: [
                        { id: 'normalRoles', label: CONSTANT_ACCESS_REQUEST.NORMAL_ROLES, type: 'separator' },
                        { id: 'adminRoles', label: CONSTANT_ACCESS_REQUEST.ADMIN_ROLES, type: 'separator' },
                        { id: 'customAdminRoles', label: CONSTANT_ACCESS_REQUEST.CUSTOM_ADMIN_ROLES, type: 'separator' },
                        { id: 'customRoles', label: CONSTANT_ACCESS_REQUEST.CUSTOM_ROLES, type: 'separator' },
                    ]
                });
            }
            if (item.account_substrate === "gcp") {
                this.setState({
                    roleSubheadings: [
                        { id: 'gcpRoles', label: CONSTANT_ACCESS_REQUEST.GCP_ROLES, type: 'separator' },
                        { id: 'normalRoles', label: CONSTANT_ACCESS_REQUEST.NORMAL_ROLES, type: 'separator' },
                        { id: 'adminRoles', label: CONSTANT_ACCESS_REQUEST.ADMIN_ROLES, type: 'separator' },
                        { id: 'customAdminRoles', label: CONSTANT_ACCESS_REQUEST.CUSTOM_ADMIN_ROLES, type: 'separator' },
                        { id: 'customRoles', label: CONSTANT_ACCESS_REQUEST.CUSTOM_ROLES, type: 'separator' },
                    ]
                });
            }
            this.setState({ roles: transformedData });

            // Set ReadOnly role as default with initial duration value from hours list
            const readOnlyRole = transformedData.find((role: { value: string; }) => role.value === "PCSKReadOnlyAccessRole");
            if(readOnlyRole) {
                await this.handleRoleSelect({}, {"selection": [readOnlyRole]})
            }
        } catch (err: any) {
            HandleError(this.props, err, TOAST_MESSAGE.ERROR_FETCHING_ROLES);
            this.setState({'dataLoading': false});
            console.error("Error fetching roles:", err.message);
        }
    }

    async handleCreateAccessRequest() {
        if (!this.validated()) {
            console.log("Validation error")
            this.props.setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ERROR_VALIDATION_DETAIL,
                    heading: TOAST_MESSAGE.ERROR_VALIDATION,
                },
                variant: "error"
            });
            return
        }
        const minor_reason = this.state.minor_reasons.length !== 0 ? this.state.selectedMinorReason[0].displayLabel : "";
        const reason_bucket: string = minor_reason
            ? `${this.state.selectedMajorReason[0].value} - ${minor_reason}`
            : this.state.selectedMajorReason[0].value;
        const recordInputValue = this.state.recordDetailsSelectedValue
        let change_case = ""
        let incident_id = ""
        if(this.state.recordDetailsTypeSelected === RECORD_TYPE.CHANGE_CASE) {
            change_case = this.state.recordDetailsTypeSelected === RECORD_TYPE.CHANGE_CASE ? recordInputValue : "";
        }
        if(this.state.recordDetailsTypeSelected === RECORD_TYPE.INCIDENT) {
            incident_id = this.state.recordDetailsTypeSelected === RECORD_TYPE.INCIDENT ? recordInputValue : "";
        }
        if(this.state.recordDetailsTypeSelected === RECORD_TYPE.EMERGENCY) {
            change_case = CONSTANT_ACCESS_REQUEST.EMERGENCY;
            incident_id = CONSTANT_ACCESS_REQUEST.EMERGENCY;
        }
        const requestData = {
            aws_account: this.state.selectedAccount,
            reason_bucket: reason_bucket,
            reason_bucket_label: reason_bucket,
            reason: this.state.detailedReason,
            role_name: this.state.selectedRole[0].value,
            duration: this.state.selectedDuration[0].value,
            change_case: change_case,
            incident: incident_id
        };
        this.props.setToast({
            isVisible: true,
            message: {
                details: TOAST_MESSAGE.ACCESS_REQUEST_CREATION_DETAIL,
                heading: TOAST_MESSAGE.ACCESS_REQUEST_CREATION,
            },
            variant: "success"
        });

        createAccessRequest(requestData).then(() => {
            this.clearSelections();
            this.props.setActiveSubTab(0);
            this.props.setToast({
                isVisible: false,
                message: {
                    details: "",
                    heading: "",
                },
                variant: "error"
            });
        }).catch((error: any) => {
            HandleError(this.props, error, TOAST_MESSAGE.ACCESS_REQUEST_CREATION_FAILED);
            this.setState({
                dataLoading: false
            })
            console.error("Error creating access request:", error.message);
        });
    }

    handleRecordDetailsInput = async (event: any) => {
        this.setState({recordDetailsSelectedValue: event.target.value});

        if(event.target.value.length < 8) {
            this.setState({
                recordDetailsSelectedInputError: CONSTANT_ACCESS_REQUEST.ENTER_VALID_RECORD_DETAILS_ERROR,
                isRecordDetailsValid: false
            })
        } else {
            this.setState({
                recordDetailsSelectedInputError: ""
            })
        }
        if(event.target.value.length >= 8) {
            this.setState({
                recordDetailsVerificationLoading: true
            });

            fetchChangeCaseStatus(event.target.value).then((response) => {
                if(response.IsValid) {
                    this.setState({
                        recordDetailsVerificationLoading: false,
                        isRecordDetailsValid: true,
                        recordSubject: response.Subject
                    })
                } else {
                    this.setState({
                        recordDetailsVerificationLoading: false,
                        isRecordDetailsValid: false,
                        recordSubject: "",
                        recordDetailsSelectedInputError: CONSTANT_ACCESS_REQUEST.CHANGE_CASE_VALIDATION_ERROR
                    })
                }
            }).catch((err) => {
                HandleError(this.props, err, TOAST_MESSAGE.ERROR_FETCHING_REASONS);
                this.setState({
                    recordDetailsVerificationLoading: false,
                    isRecordDetailsValid: false,
                    recordSubject: "",
                    recordDetailsSelectedInputError: CONSTANT_ACCESS_REQUEST.CHANGE_CASE_VALIDATION_ERROR
                })
            });
        }
    }

    setSelectedAccount(accountID: string) {
        const selectedIndex = this.state.accounts.findIndex(opt => opt.id === accountID);
        this.setState({
            selectedAccountIndex: selectedIndex
        })
    }

    convertToHourList(values: number[]) {
        const transformedData: { id: string; label: string; value: number }[] = values.map((hour: number) => ({
            id: hour.toString(),
            label: this.displayHour(hour),
            value: hour
        }))
        return transformedData;
    }

    handleDetailedReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ detailedReason: event.target.value });
    }

    calculateRoleType(role: { id: any; name: string; hours: number[]; account_id: string, role_substrate: string, path: string }) {
        // Admin Roles
        if ((role.name === ROLES.PCSKAdministratorAccessRole && role.role_substrate === ROLES.ROLE_SUBSTRATE_AWS) || role.name === ROLES.PCSKLimitedAdministratorAccessRole) {
            return "adminRoles";
        }
        // PCSK Normal Roles
        if (role.name.indexOf("PCSK") === 0 && role.role_substrate === ROLES.ROLE_SUBSTRATE_AWS) {
            return "normalRoles";
        }
        if (role.role_substrate === ROLES.ROLE_SUBSTRATE_AWS && role.path.startsWith("/pcsk/custom/admin")) {
            return "customAdminRoles"
        }
        // Custom Roles
        if ((role.role_substrate === ROLES.ROLE_SUBSTRATE_AWS && role.name.startsWith('TEAM_DEFINED_PCSKROLE')) ||
            (role.role_substrate === ROLES.ROLE_SUBSTRATE_AWS && role.path.startsWith("/pcsk/custom"))) {
            return "customRoles";
        }
        // GCP Roles
        if (role.role_substrate === ROLES.ROLE_SUBSTRATE_GCP) {
            return "gcpRoles";
        }
        return "customRoles"
    }

    clearSelections() {
        this.setState({
            approvers : [],
            roles: [],
            durations: [],
            selectedAccountIndex: -1,
            selectedAccount: "",
            selectedTeamName: "",
            selectedRole: [],
            selectedDuration: [],
            accountStatus: "",
            selectedMajorReason: [],
            selectedMinorReason: [],
            selectedMinorReasonInfo: "",
            selectedRoleDescription: "",
            loadingApprovers: false,
            roleSubheadings: [],
            detailedReason: "",
            isRoleDisabled: true,
            isDurationDisabled: true,
            isMajorReasonDisabled: true,
            isMinorReasonDisabled: true,
            isDetailedReasonDisabled: true,
            isCreateButtonDisabled: true,
            recordDetailsSelectedValue: "",
            isRecordDetailsVisible: false,
            isRecordDetailsValid: false,
            recordSubject: ""
        });
    }

    displayHour(h: number): string {
        const HOURS_PER_DAY = 24;
        return h < HOURS_PER_DAY ? (h + 'h') : (h / HOURS_PER_DAY + 'd')
    }
    private validated() {
        const hasSelectedAccount = this.state.selectedAccountIndex !== -1;
        const hasSelectedRole = this.state.selectedRole.length !== 0;
        const hasSelectedDuration = this.state.selectedDuration.length !== 0;
        const hasDetailedReason = this.state.detailedReason !== "";
        const hasSelectedMajorReason = this.state.selectedMajorReason.length !== 0;
        const needsMinorReason = this.state.minor_reasons.length !== 0;
        const hasSelectedMinorReason = this.state.selectedMinorReason.length !== 0;

        let isChangeCaseValidationEnabled = `${process.env.REACT_APP_PCSK_CHANGE_CASE_VALIDATION_ENABLED}` === "true";
        const hasValidChangeCaseID = isChangeCaseValidationEnabled ? (this.state.isRecordDetailsVisible ? (this.state.isRecordDetailsValid || this.state.recordDetailsTypeSelected === RECORD_TYPE.EMERGENCY) : true) : true

        if (!hasSelectedAccount || !hasSelectedRole || !hasSelectedDuration || !hasDetailedReason || !hasSelectedMajorReason || !hasValidChangeCaseID) {
            return false;
        }

        return !(needsMinorReason && !hasSelectedMinorReason);
    }

    render() {
        if (this.state.dataLoading) {
            return (<EmptyPageSpinner/>);
        }
        return (
            <div style={{minHeight: "80vh"}}>
                <div className="slds-grid slds-gutters">
                    <div className="slds-col slds-size--7-of-12">
                        <Card heading="New Access Request">
                            <div className="slds-p-around_small">
                                <div className="slds-p-around--xx-small">
                                    <Lookup
                                        emptyMessage="No items found"
                                        hasError={false}
                                        label={CONSTANT_ACCESS_REQUEST.ACCOUNT_ID}
                                        required
                                        selectedItem={this.state.selectedAccountIndex}
                                        options={this.state.accounts}
                                        onSelect={this.handleAccountSelect.bind(this)}
                                        onUnselect={this.clearSelections.bind(this)}
                                    />
                                    {
                                        this.state.accountStatus === CONSTANT_ACCESS_REQUEST.CLOSE_REQUESTED && <div className='slds-var-m-around_xx-small slds-color__text_gray-8'>{CONSTANT_ACCESS_REQUEST.NOTE_LABEL} {CONSTANT_ACCESS_REQUEST.ACCOUNT_PENDING_CLOSE_MSG}</div>
                                    }
                                </div>
                                <div className="slds-p-around--xx-small">
                                    <Combobox
                                        id="role"
                                        events={{
                                            onSelect: this.handleRoleSelect.bind(this)
                                        }}
                                        labels={{
                                            label: CONSTANT_ACCESS_REQUEST.ROLE,
                                            placeholder: CONSTANT_ACCESS_REQUEST.SELECT_ROLE,
                                        }}
                                        required
                                        singleInputDisabled={this.state.isRoleDisabled}
                                        menuPosition="absolute"
                                        // options={this.state.roles}
                                        options={comboboxAddSubheadings({
                                            subheadings: this.state.roleSubheadings,
                                            filteredOptions: comboboxFilterAndLimit({
                                                options: this.state.roles,
                                                selection: this.state.selectedRole,
                                                limit: 10000
                                            }),
                                        })}
                                        menuItemVisibleLength={7}
                                        selection={this.state.selectedRole}
                                        variant="readonly"
                                    />
                                    {/*<div className='slds-var-m-around_xx-small slds-color__text_gray-9'>{this.state.selectedRoleDescription}</div>*/}
                                    <div className='slds-var-m-around_xxx-small slds-color__text_gray-9'>{CONSTANT_ACCESS_REQUEST.ROLE_MORE_DETAILS} <a href="https://git.soma.salesforce.com/identity-access/aws-master/blob/master/stacks/stacksets/roleDescription.md" target="_blank" rel="noopener noreferrer">here</a>.</div>
                                    { this.state.isRecordDetailsVisible &&
                                        <div className='slds-var-m-around_xxx-small slds-text-color--error'>
                                            {CONSTANT_ACCESS_REQUEST.READ_ONLY_NOTE}
                                        </div>
                                    }
                                </div>
                                <div className="slds-p-around--xx-small">
                                    <Combobox
                                        id="duration"
                                        events={{
                                            onSelect: (event:any, data:any) => {
                                                this.setState({
                                                    selectedDuration: data.selection,
                                                    isMajorReasonDisabled: false
                                                });
                                            }
                                        }}
                                        singleInputDisabled={this.state.isDurationDisabled}
                                        labels={{
                                            label: CONSTANT_ACCESS_REQUEST.DURATION,
                                            placeholder: CONSTANT_ACCESS_REQUEST.SELECT_DURATION,
                                        }}
                                        required
                                        menuPosition="absolute"
                                        options={comboboxFilterAndLimit({
                                            inputValue: "",
                                            options: this.state.durations,
                                            selection: this.state.selectedDuration,
                                            limit: 10000
                                        })}
                                        selection={this.state.selectedDuration}
                                        variant="readonly"
                                    />
                                </div>
                                <div className="slds-p-around--xx-small">
                                    <Combobox
                                        id="major_reason"
                                        events={{
                                            onSelect: (event:any, data:any) => {
                                                this.setState({
                                                    selectedMajorReason: data.selection,
                                                    minor_reasons: data.selection[0].minor_reasons,
                                                    selectedMinorReason: [],
                                                    isMinorReasonDisabled: data.selection[0].minor_reasons.length === 0,
                                                    isDetailedReasonDisabled: false
                                                });
                                            }
                                        }}
                                        labels={{
                                            label: CONSTANT_ACCESS_REQUEST.MAJOR_REASON,
                                            placeholder: CONSTANT_ACCESS_REQUEST.SELECT_MAJOR_REASON,
                                        }}
                                        required
                                        singleInputDisabled={this.state.isMajorReasonDisabled}
                                        menuPosition="absolute"
                                        options={comboboxFilterAndLimit({
                                            options: this.state.major_reasons,
                                            selection: this.state.selectedMajorReason,
                                            limit: 10000
                                        })}
                                        menuItemVisibleLength={7}
                                        selection={this.state.selectedMajorReason}
                                        variant="readonly"
                                    />
                                </div>
                                <div className="slds-p-around--xx-small">
                                    <Combobox
                                        id="minor_reason"
                                        events={{
                                            onSelect: (event:any, data:any) => {
                                                this.setState({
                                                    selectedMinorReason: data.selection,
                                                    selectedMinorReasonInfo: data.selection[0].info_prompted
                                                });
                                            }
                                        }}
                                        labels={{
                                            label: CONSTANT_ACCESS_REQUEST.MINOR_REASON,
                                            placeholder: CONSTANT_ACCESS_REQUEST.SELECT_MINOR_REASON,
                                        }}
                                        required={this.state.minor_reasons.length !== 0}
                                        menuPosition="absolute"
                                        singleInputDisabled={this.state.isMinorReasonDisabled}
                                        options={comboboxFilterAndLimit({
                                            options: this.state.minor_reasons,
                                            selection: this.state.selectedMinorReason,
                                            limit: 10000
                                        })}
                                        menuItemVisibleLength={7}
                                        selection={this.state.selectedMinorReason}
                                        variant="readonly"
                                    />
                                    <div className='slds-p-around--xx-small slds-color__text_gray-9'>{this.state.selectedMinorReasonInfo}</div>
                                </div>
                                {this.state.isRecordDetailsVisible && <div className="slds-p-around--xx-small">
                                    <div className="slds-p-bottom--xx-small">
                                        <RadioGroup
                                            labels={{ label: CONSTANT_ACCESS_REQUEST.RECORD_DETAILS_LABEL }}
                                            name="record_details"
                                            variant="button-group"
                                            required
                                            onChange={(event: any) => {
                                                this.setState({
                                                    recordDetailsTypeSelected: event.target.value,
                                                });
                                                if (event.target.value === RECORD_TYPE.CHANGE_CASE) {
                                                    this.setState({
                                                        recordDetailsTypeSelectedInputPlaceholder: CONSTANT_ACCESS_REQUEST.VALID_CHANGE_CASE
                                                    })
                                                }
                                                if (event.target.value === RECORD_TYPE.INCIDENT) {
                                                    this.setState({
                                                        recordDetailsTypeSelectedInputPlaceholder: CONSTANT_ACCESS_REQUEST.VALID_INCIDENT_ID
                                                    })
                                                }
                                                if (event.target.value === RECORD_TYPE.EMERGENCY) {
                                                    this.setState({
                                                        recordDetailsSelectedValue: "",
                                                        isRecordDetailsValid: false,
                                                        recordDetailsSelectedInputError: ""
                                                    })
                                                }
                                            }}
                                        >
                                            <Radio
                                                key="change_case"
                                                id="change_case"
                                                labels={{ label: CONSTANT_ACCESS_REQUEST.CHANGE_CASE }}
                                                value={RECORD_TYPE.CHANGE_CASE}
                                                checked={this.state.recordDetailsTypeSelected === RECORD_TYPE.CHANGE_CASE}
                                                variant="button-group"
                                            />
                                            <Radio
                                                key="incident_id"
                                                id="incident_id"
                                                labels={{ label: CONSTANT_ACCESS_REQUEST.INCIDENT_ID }}
                                                value={RECORD_TYPE.INCIDENT}
                                                checked={this.state.recordDetailsTypeSelected === RECORD_TYPE.INCIDENT}
                                                variant="button-group"
                                            />
                                            <Radio
                                                key="emergency"
                                                id="emergency"
                                                labels={{ label: CONSTANT_ACCESS_REQUEST.EMERGENCY }}
                                                value={RECORD_TYPE.EMERGENCY}
                                                checked={this.state.recordDetailsTypeSelected === RECORD_TYPE.EMERGENCY}
                                                variant="button-group"
                                            />
                                        </RadioGroup>
                                    </div>
                                    {this.state.recordDetailsTypeSelected === RECORD_TYPE.EMERGENCY &&
                                        <div className="slds-text-color--error">
                                            <p>
                                                For services on Falcon, emergency access requests will be monitored and
                                                reviewed by your management chain and the Falcon Leadership Team.
                                            </p>
                                            <p>
                                                For services not on Falcon, please refer to your team’s policy on submitting
                                                access requests without a change or incident case number.
                                            </p>
                                        </div>
                                    }
                                    {this.state.recordDetailsTypeSelected !== RECORD_TYPE.EMERGENCY  &&
                                        <div>
                                            <Input id="record_details_input"
                                                   value={this.state.recordDetailsSelectedValue}
                                                   onChange={this.handleRecordDetailsInput}
                                                   errorText={this.state.recordDetailsSelectedInputError}
                                                   hasSpinner={this.state.recordDetailsVerificationLoading}
                                                   placeholder={this.state.recordDetailsTypeSelectedInputPlaceholder}
                                                   type="number"
                                            />
                                            {this.state.isRecordDetailsValid && this.state.recordSubject &&
                                                <p className="slds-p-around--xx-small slds-text-color_success">Subject: {this.state.recordSubject}</p>
                                            }
                                        </div>
                                    }
                                </div>
                                }
                                <div className="slds-p-around--xx-small">
                                    <Textarea
                                        id="detailedReasonTextBox"
                                        className="slds-textarea"
                                        label={CONSTANT_ACCESS_REQUEST.DETAILED_REASON}
                                        required
                                        disabled={this.state.isDetailedReasonDisabled}
                                        onChange={this.handleDetailedReasonChange}
                                        value={this.state.detailedReason}
                                        placeholder={CONSTANT_ACCESS_REQUEST.DETAILED_REASON_PLACEHOLDER}
                                    />
                                </div>
                                <div className="slds-p-around--xx-small slds-m-top--medium">
                                    <Button key='createAccessRequestButton' label={CONSTANT_ACCESS_REQUEST.CREATE_BUTTON} variant="brand"
                                            onClick={this.handleCreateAccessRequest.bind(this)}
                                            disabled={this.state.isCreateButtonDisabled}/>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="slds-col slds-size--5-of-12">
                        <Card hasNoHeader={true}>
                            <div><Approvers approvers={this.state.approvers} slackChannel={this.state.slack_channels[this.state.selectedTeamName]} loading={this.state.loadingApprovers}/></div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateAccessRequests;
