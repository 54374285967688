
import {BASE_URL, HandleErrorResponse} from "../common";
import {PCSKAccountClosureApprover, PCSKAccountClosureItem} from "../../utils/types/accountClosure";
import {AccountClosureApiService} from "./service"

export class MainAccountClosureApiService implements AccountClosureApiService {
    readonly basePath = `${BASE_URL}/account-closures`;
    async getClosureApprovers(aws_account_id: string): Promise<PCSKAccountClosureApprover[]> {
        const endpoint = `${this.basePath}/${aws_account_id}/approvers`;

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item, index) => {
                return new PCSKAccountClosureApprover(item.email, item.type);
            });

        } catch (error) {
            console.error("Error fetching closure approvers:", error);
            throw error;
        }
    }

    async approveAccountClosure(aws_account_id: string): Promise<PCSKAccountClosureItem> {
        const endpoint = `${this.basePath}/approve`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
                body: JSON.stringify({ "aws_account_id": aws_account_id })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const respJSON = await response.json();
            return asPCSKAccountClosureItem(respJSON, 1);

        } catch (error) {
            console.error("Error approving closure request:", error);
            throw error;
        }
    }

    async getAccountClosures(): Promise<PCSKAccountClosureItem[]> {
        try {
            const response = await fetch(this.basePath, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonArray: any[] = await response.json();
            return jsonArray.map((item, index) => {
                return asPCSKAccountClosureItem(item, index + 1);
            });

        } catch (error) {
            console.error("Error fetching active account closures:", error);
            throw error;
        }
    }

}
const asPCSKAccountClosureItem = (response: any, index = 1): PCSKAccountClosureItem => {
    return new PCSKAccountClosureItem({
        approval_expiry_timestamp: response.approval_expiry_timestamp,
        approval_ids: response.approval_ids,
        approved_by_emails: response.approved_by_emails,
        aws_account_id: response.aws_account_id,
        account_uuid: response.account_uuid,
        can_approve: response.can_approve,
        closure_work_item_id: response.closure_work_item_id,
        closed_at: response.closed_at,
        email: response.email,
        fd: response.fd,
        fi: response.fi,
        force_approval_reason: response.force_approval_reason,
        force_approved_by_email: response.force_approved_by_email,
        has_crossed_waiting_period: response.has_crossed_waiting_period,
        index: index,
        is_approved: response.is_approved,
        is_closed: response.is_closed,
        is_expired: response.is_expired,
        is_falcon: response.is_falcon ?? false,
        last_notification_timestamp: response.last_notification_timestamp,
        min_approvers: response.min_approvers,
        name: response.name,
        org_account_id: response.org_account_id,
        requester_arn: response.requester_arn,
        request_timestamp: response.request_timestamp,
        scheduled_closure_message: response.scheduled_closure_message,
        sent_notifications_count: response.sent_notifications_count,
        services_examined: response.services_examined,
        state: response.state,
        status: response.status,
        team: response.team,
        waiting_period: response.waiting_period
    });
};